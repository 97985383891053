* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: inherit;
}

html,
body {
  font: 300 10px/1.4 "Dosis", "Helvetica", "Arial", sans-serif;
  color: #77787B;
}

div, section, p, ul, ol, li {
  font-size: 1.6rem;
}

p {
  margin-bottom: 2.8rem;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
}

h2 {
  font-size: 2.8rem;
  color: #02AEEF;
  color: #404040;
  text-transform: uppercase;
}

.site-width {
  max-width: 1080px;
  width: calc(100% - 12rem);
  margin-left: auto;
  margin-right: auto;
  @include phone {
    width: calc(100% - 5rem);
  }
}

main {
  display: block;
}