/*
=========================
HEADER
=========================
*/
header {
  background-image: linear-gradient(rgba(2,174,239,1), rgba(2,174,239,0));
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transition: all 300ms ease-out;
}

header.header--is-fixed {
  background-color: #fff;
  background-image: linear-gradient(rgba(255,255,255,1), rgba(255,255,255,1));
  box-shadow: 0 5px 15px rgba(black, 0.1);
}

.site-nav {
  padding: 2rem 0 5rem;
  transition: all 300ms ease-out;
  display: flex;
  align-items: center;

  > a {
    flex-grow: 1;
  }
  
  svg {
    max-width: 175px;
    transition: all 300ms ease-out;
    display: block;
    @include phone {
      max-width: 125px;
    }
  }

}

header.header--is-fixed .site-nav {
  padding: 1rem 0;
  @include desktop {
    padding: 0;
  }
  &:after {
    content: '';
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 65px;
    z-index: -1;
    opacity: 1;
    transition: all 300ms ease-out;
    .mobile-nav--is-fixed & {
      opacity: 0;
    }
  }
  
  svg {
    max-width: 100px;
    @include phone {
      max-width: 85px;
    }
  }

  svg path {
    fill: #02AEEF !important;
  }

}

.site-nav ul {
  display: flex;
  list-style-type: none;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 50px;
  justify-content: center;
  background-color: #f0f0f0;
  box-shadow: 0 0 15px rgba(black, 0.1);
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  transform: translateY(-100%);
  z-index: -1;
  visibility: hidden;
  transition: all 300ms ease-out;
  @include desktop {
    background-color: transparent;
    box-shadow: none;
    border-top: 0;
    border-bottom: 0;
    position: unset;
    top: 0;
    transform: translateY(0);
    visibility: visible;
    z-index: 0;
  }
  
  .mobile-nav--is-fixed & {
    visibility: visible;
    transform: translateY(0);
  }

}

.site-nav ul a {
  display: block;
  color: #fff;
  text-decoration: none;
  transition: all 300ms ease-out;
  padding: 1rem 3rem;
  font-size: 0.8em;
  @include desktop {
    padding: 2rem 1rem;
    font-size: inherit;
  }
  
  &:hover {
    @include desktop {
      box-shadow: inset 0 -5px 0 #ccc;
    }
  }
  
  &.active {
    box-shadow: inset 0 -5px 0 $blue;
    font-weight: bold;
  }

}

// .site-nav ul a,
.site-nav button {
  color: #fff;
  text-decoration: none;
  display: block;
  margin: 0 2rem;
  background-color: transparent;
  border: 0;
  @include phone {
    margin: 0;
    font-size: 0.8em;
  }
}

header.header--is-fixed .site-nav ul a {
  color: inherit;
}

.site-nav button {
  background-color: rgba(0,0,0,0.2);
  border: 1px solid #fff;
}

header.header--is-fixed .site-nav button {
  background-color: #02AEEF;
}