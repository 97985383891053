.modal--is-open {
  position: fixed;
  overflow: hidden;
}

.modal {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(black, 0.8);
  pointer-events: none;
  opacity: 0;
  transition: all 200ms ease-out;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  
  
  &.modal--is-active {
    pointer-events: initial;
    opacity: 1;
  }

}

.modal-container {
  max-width: 600px;
  margin: 3rem auto;
  transform: scale(0.9) translateY(6rem);
  transition: all 300ms cubic-bezier(0, 0, 0.33, 1.74);
  
  .modal--is-active & {
    transform: scale(1) translateY(0);
  }

}

.modal-actions {
  text-align: right;
  margin-bottom: 2rem;
  
  button {
    position: relative;
    background-color: transparent;
    border: 0;
    color: #fff;
    font-weight: bold;
    color: transparent;

    &:before {
      content: '\00d7';
      color: #fff;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 3rem;
      line-height: 0;
      height: 0.8rem;
    }
    
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 1px solid #fff;
      border-radius: 50%;
      transition: all 300ms ease-out;
    }

    &:hover:before,
    &:focus:before {
      color: #77787B;
    }

    &:hover:after,
    &:focus:after {
      background-color: #fff;
    }
    
  }

}

.modal-content {
  background-color: #fff;
  padding: 5rem;
  border-radius: 15px;
  box-shadow: 3px 3px 20px rgba(black, 0.7);
}

.modal--lets-talk form {
  max-width: 400px;
  margin: 0 auto;
}