$blue: #02AEEF;
$teal: #00DCD9;
$black: #77787B;

@mixin desktop {
  @media screen and (min-width: 767px) {
    @content;
  }
}

@mixin phone {
  @media screen and (max-width: 600px) {
    @content;
  }
}



@import 'src/scss/reset';
@import 'src/scss/base';
@import 'src/scss/modules/_header';
@import 'src/scss/modules/_hero';
@import 'src/scss/modules/_box';
@import 'src/scss/modules/_button';
@import 'src/scss/modules/_modal';
@import 'src/scss/modules/_form';
@import 'src/scss/modules/_footer';


@import 'src/scss/pages/_home';


@import 'src/scss/utilities';