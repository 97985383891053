input[type="text"],
input[type="email"] {
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 5px;
  padding: 0.5rem;
}

.form-group {
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  
  > input {
    min-width: 250px;
  }

  > label {
    min-width: 125px;
  }

}

form .btn-solid--blue {
  display: block;
  margin: 3rem auto 0;
  padding: 1rem 2.5rem;
}