.hero {
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, rgba(2,174,239,0.75), rgba(2,174,239,0.75)), url('./img/hero.jpg');
  background-size: cover;
  background-position: center;
  -webkit-clip-path: polygon(0% 0%, 560% 0%, 0% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 560% 0%, 0% 100%, 0% 100%);
  padding: 15rem 0 10rem;
  @include desktop {
    background-attachment: fixed;
    background-size: contain;
    background-position: top right;
    padding: 23rem 0 15rem;
  }
}

.hero h1 {
  font-family: "Dosis", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  transform: rotate(-4deg);
  transform-origin: 0 0;
  max-width: 625px;
  color: #fff !important;
  text-shadow: 2px 2px 4px rgba(black, 0.4);
  font-size: 6vw;
  span {
    font-size: 5vw;
  }
  @include desktop {
    font-size: 4rem;
    span {
      font-size: inherit;
    }
  }
}

// .hero {
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-attachment: fixed;
//   position: relative;
//   z-index: 1;
//   overflow: hidden;
//   padding-top: 15rem;
//   padding-bottom: 15rem;
//   -webkit-clip-path: polygon(0% 0%, 560% 0%, 0% 100%, 0% 100%);
//   clip-path: polygon(0% 0%, 560% 0%, 0% 100%, 0% 100%);
//   @include desktop {
//     background-image: linear-gradient(to right, rgba(2,174,239,1), rgba(2,174,239,0)), url('./img/hero.jpg');
//     background-size: cover, 65vw;
//     padding-top: 23rem;
//     padding-bottom: 15rem;
//     background-position: center right;
//   }
//   @include phone {
//     background-image: linear-gradient(to right, rgba(2,174,239,0.75), rgba(2,174,239,0.75)), url('./img/hero.jpg');
//   }
// }

// .hero h1 {
//   font-family: "Dosis", "Helvetica", "Arial", sans-serif;
//   font-weight: 300;
//   font-size: 4rem;
//   transform: rotate(-4deg);
//   transform-origin: 0 0;
//   font-weight: 300;
//   max-width: 625px;
//   color: #fff !important;
//   text-shadow: 2px 2px 4px rgba(black, 0.4); 
//   @include phone {
//     font-size: 6vw;
//     span {
//       font-size: 5vw;
//     }
//   }
// }