.app-sections {  

  section {
    padding: 5rem 0;
    @include desktop {
      display: flex;
      align-items: center;
      font-size: 1.5em;
      padding: 10rem 0;
    }

    * {
      font-size: inherit;
    }

    h2 {
      font-size: 1.5em;
      line-height: 1;
    }
    
    > * {
      flex: 1;
    }

    &:nth-child(2n-1) {
      > *:first-child {
        order: 1;
      }
    }

    img {
      display: block;
      margin: 0 auto 3rem;
      width: 100%;
      max-width: 200px;
      @include desktop {
        margin: 0 auto;
        max-width: 300px;
      }
    }

    .section-img {
      position: relative;
      &:after {
        position: absolute;
        content: '';
        top: 100%;
        height: 30px;
        background-color: rgba(black, 0.4);
        width: 100%;
        max-width: 200px;
        left: 50%;
        transform: translate(-50%, -30px);
        filter: blur(20px);
        z-index: -1;
        @include desktop {
          max-width: 300px;
        }
      }
      // &:after {
      //   content: url('./img/screens--shadow.jpg');
      //   position: absolute;
      //   top: 100%;
      //   left: 0;
      //   right: 50%;
      //   // max-width: 100px;
      //   margin: 0 auto;
      //   width: 100%;
      // }
    }

  &#personalized-sites .section-img:after,
  &#messaging .section-img:after {
    @include desktop {
      max-width: 225px;
    }
  }

  }


  svg {
    display: block;
    width: 100%;
    max-width: 30px;
    max-height: 30px;
    filter: drop-shadow(2px 2px 2px rgba(black, 0.2));
    @include desktop {
      max-width: 50px;
      max-height: 50px;
    }
    
    path {
      fill: #fff;
    }
  }

  .section-copy {
    display: flex;
  }

  .section-icon {
    width: 100%;
    max-width: 60px;
    min-width: 60px;
    max-height: 60px;
    min-height: 60px;
    background-color: $blue;
    background-image: linear-gradient(125deg, lighten($teal, 5%), $blue);
    border: 3px solid #fff;
    border-radius: 50%;
    box-shadow: 0 0 25px rgba(black, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    @include desktop {
      max-width: 100px;
      min-width: 100px;
      max-height: 100px;
      min-height: 100px;
      border: 5px solid #fff;
      margin-right: 3rem;
    }
  }

}

.spiritual-journey-section {
  padding: 15rem 0 20rem;
  background-color: black;
  background-image: url('./img/runOnePattern.png');
  background-attachment: fixed;
  position: relative;
  overflow: hidden;
  -webkit-clip-path: polygon(0 10%, 100% 0%, 100% 87%, 0% 100%);
  clip-path: polygon(0 10%, 100% 0%, 100% 87%, 0% 100%);

  h2 {
    color: darken(#fff, 0.5);
    padding-bottom: 3rem;
    transform: rotate(-4deg);
    transform-origin: 0 0;
    font-size: 10.8vw;
    line-height: 1.2;
    @include desktop {
      font-size: 2.5em;
    }

    .paint-stroke {
      font-size: 0.8em;
    }

    span {
      color: #fff;
    }
  }

  .box {
    position: relative;
  }

  .box img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }

  .subtext {
    @include desktop {
      bottom: 3rem;
      left: 5rem;
      position: absolute;
      max-width: 300px;
    }
  }

}

.services-section {
  padding: 10rem 0 15rem;
  
  h2 {
    padding-bottom: 5rem;
    transform: rotate(-4deg);
    transform-origin: 0 0;
    font-size: 11vw;
    line-height: 1.2;
    @include desktop {
      font-size: 2.5em;
    }
    
    .paint-stroke {
      font-size: 1em;
    }

  }

  .subtext {
    font-size: 1.2em;
    // max-width: 400px;
  }

}

.services--list {
  display: flex;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5rem;
  padding: 0;
  margin: 0;
  list-style-type: none;
  @include phone {
    grid-template-columns: 1fr;
  }
}

.services--item {
  flex: 1;
  background-color: #000;
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding: 3rem 0;
  @include phone {
    padding: 0;
  }
  
  h4 {
    position: relative;
    transform: translateY(-100%);
    transition: all 400ms ease-out;
    color: #fff;
    font-size: 2rem;
    @include phone {
      transform: translateY(calc(-100% - 3rem));
    }
    
    img {
      display: inline-block;
      margin-right: 1rem;
    }

  }

  &:hover h4 {
    transform: translateY(-2.5rem);
  }

  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(black, 0.75);
    transform: translateY(calc(100% - 6rem));
    z-index: -1;
    transition: all 400ms ease-out;
  }

  &:hover:after {
    background-color: rgba(black, 0.2);
    transform: translateY(0);
  }

  p {
    font-size: 0.9em;
  }

}

.services--item-bg {
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 400ms ease-out;
  width: 100%;
  
  .services--item:hover & {
    filter: blur(10px);
    opacity: 0.5;
  }

}

.services--item-content {
  position: relative;
  padding: 1.5rem;
  transform: translateY(100%);
  transition: all 400ms ease-out;

  img {
    // max-height: 2.3rem;
    max-height: 3rem;
    max-width: 3rem;
    vertical-align: middle;
  }
  
  * {
    margin: 0;
    padding: 0;
    color: #fff;
    text-shadow: 2px 2px 3px rgba(black, 0.6);
  }

  .services--item:hover & {
    transform: translateY(0);
  }

}

.testimonial-section {
  background-image: linear-gradient(rgba(black, 0.75), rgba(black, 0.75)), url('./img/testimonial--itown.jpg');
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  padding: 15rem 0;
  // -webkit-clip-path: polygon(0 30%, 100% 0%, 100% 75%, 0% 100%);
  clip-path: polygon(0 15%, 100% 0%, 100% 85%, 0% 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  // position: relative;
  
  blockquote {
    max-width: 500px;
    margin: 0 auto;
    position: relative;
    
    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      top: -3rem;
      right: -3rem;
      bottom: -3rem;
      left: -3rem;
      background-color: #000;
      filter: blur(25px);
      opacity: 0.2;
    }

  }

  p, cite {
    color: #fff;
    text-shadow: 2px 2px 3px rgba(black, 0.8);
    font-size: 2.5rem;
    span {
      display: block;
      line-height: 1;
      font-size: 0.7em;
    }
  }

  .testimonial-credit {
    display: flex;
    align-items: center;
  }

  img {
    border: 4px solid #fff;
    box-shadow: 1px 1px 10px rgba(black, 0.7);
    max-width: 75px;
    border-radius: 50%;
    margin-right: 2rem;
  }

}

.team-section {
  padding: 10rem 0;
  position: relative;
  z-index: 1;

  h2 {
    padding-bottom: 5rem;
    transform: rotate(-4deg);
    transform-origin: 0 0;
    font-size: 11vw;
    line-height: 1.2;
    @include desktop {
      font-size: 2.5em;
    }
    
    .paint-stroke {
      font-size: 1em;
    }

  }

}

.team--list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  display: grid;
  align-items: flex-start;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5rem 10rem;
  @include phone {
    grid-template-columns: 1fr;
  }
}

.team--item {
  display: flex;
  flex-basis: 50%;
  align-items: flex-start;
}

.team--headshot {
  box-shadow: inset 0 0 25px rgba(black, 0.8);
  border-radius: 50%;
  position: relative;
  border: 8px solid white;
  box-shadow: 0px 0px 15px rgba(black, 0.3);
  @include phone {
    border-width: 5px;
  }
  
  img {
    max-width: 125px;
    border-radius: 50%;
    display: block;
    position: relative;
    z-index: -1;
    filter: grayscale(1);
    @include phone {
      max-width: 50px;
    }
  }

}

.team--content {
  padding-left: 2rem;
  @include phone {
    padding-left: 1rem;
  }
  
  h4 {
    text-transform: uppercase;
  }

  p {
    font-size: 0.9em;
    margin-bottom: 1rem;
  }

}

.team--social-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.team--social-item {
  
  a {
    display: inline-block;
    background-color: #D1D3D4;
    border-radius: 50%;
    position: relative;
    padding: 1.5rem;
    
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 15px;
      display: block;
      width: 100%;
    }

  }

}

.cta-section {
  padding: 10rem 0 10rem;
  background-color: black;
  background-image: url('./img/cta-bg.jpg');
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  color: #fff;
  -webkit-clip-path: polygon(0% 18%, 100% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(0% 18%, 100% 0%, 100% 100%, 0% 100%);
  @include desktop {
    padding: 20rem 0 15rem;   
    background-attachment: fixed;
  }

  h2 {
    color: inherit;
    padding-bottom: 6rem;
    transform: rotate(-4deg);
    transform-origin: 0 0;
    font-size: 10vw;
    line-height: 1.2;
    @include desktop {
      font-size: 2.75em;
    }
    
    .paint-stroke--sibling {
      text-shadow: 2px 2px 4px rgba(black, 0.7);
    }

    .paint-stroke {
      color: #fff;
      font-size: 0.9em;
    }

  }

  .btn-solid--blue {
    font-size: 1.25em;
    padding: 0.7em 1.5em;
  }

}