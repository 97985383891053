.has-text-center {
  text-align: center;
}

.mar-b {
  margin-bottom: 3rem;
  &--most {
    margin-bottom: 6rem;
  }
}

.has-paint-stroke {
  font-family: "Dosis", "Helvetica", "Arial", sans-serif !important;
  text-transform: none;
  font-weight: 300;
  color: #77787b;
}

.paint-stroke {
  color: #fff;
  // white-space: pre;
  font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
  display: inline-block;
  position: relative;
  z-index: -1;
  font-weight: bold;
  margin-right: 1rem;
  text-shadow: 2px 2px 4px rgba(black, 0.4);
  @include phone {
    // span {
      font-size: 2.5rem;
      line-height: 1.2;
    // }
  }
}

.paint-stroke:after {
  position: absolute;
  content: '';
  top: 0;
  left: -0.5rem;
  right: -0.5rem;
  bottom: 0;
  background-image: linear-gradient(45deg, rgba($teal, 0.8), rgba(lighten($teal, 35%), 0.8));
  mix-blend-mode: multiply;
  z-index: -1;
  transform: skew(-25deg) rotate(-3deg);
  @include phone {
    left: -1rem;
    top: -0.5rem;
  }
}

.paint-stroke--sibling {
  display: block;
}

@keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 0px rgba($teal, 0.4), 1px 1px 10px rgba(black, 0.4);
  }
  100% {
    box-shadow: 0 0 0 10px rgba($teal, 0), 1px 1px 10px rgba(black, 0.4);
  }
}